import { Input, Select } from "antd";
import React from "react";
import ImageComponent from "../Image/ImageComponent";
import { isEmpty } from "../../Utils";
import "../Select/select.scss";
import { searchIcon } from "../../assest";

const SelectComponent = ({
  type,
  name,
  placeholder,
  handleSelectChange,
  value,
  defaultValue,
  SelectClassNames,
  isSearch,
  searchValue,
  handleSearchChange,
  options,
  error,
  inputMain,
  handleBlur,
  disabled,
  mode,
  maxCount,
  // showSearch,
  isFilter,
}) => {
  const { Option } = Select;
  const filterOption = (input, option) =>
    (option?.label ?? "")?.toLowerCase()?.startsWith(input?.toLowerCase());
  return (
    <div className={`${inputMain || ""} input-area-main`}>
      <Select
        type={type}
        name={name}
        mode={mode}
        defaultOpen={false}
        showSearch={true}
        filterOption={filterOption}
        options={options}
        placeholder={placeholder}
        allowClear={false}
        maxCount={maxCount}
        onBlur={() => handleBlur(name)}
        onChange={(e, obj) => handleSelectChange(e, name, "select", obj)}
        value={value || defaultValue}
        disabled={disabled}
        autoFocus={false}
        className={`dropdown_input ${SelectClassNames}`}
        dropdownRender={(menu) => (
          <div>
            {isSearch && (
              <Input
                name="Search"
                placeholder="Search"
                suffix={
                  <ImageComponent
                    imageSrc={searchIcon}
                    imageAlt={"search-icon"}
                    imageClassName={"search-icon"}
                  />
                }
                value={searchValue}
                onChange={handleSearchChange}
                className="search-input"
              />
            )}
            {menu}
          </div>
        )}
      >
        {Array.isArray(options) &&
          [...options]
            ?.sort((a, b) =>
              typeof a?.label === "string" && typeof b?.label === "string"
                ? a?.label?.localeCompare(b?.label)
                : a
            )
            ?.map((obj) => (
              <Option
                value={isFilter ? obj?.label : obj?.value}
                name={name}
                key={obj?.value}
              >
                {obj?.label}
              </Option>
            ))}
      </Select>
      {!isEmpty(error) && <p className="error-msg">{error}</p>}
    </div>
  );
};

export default SelectComponent;
