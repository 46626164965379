import React, { useEffect, useState } from "react";
import ProductPOSListView from "./ProductPOSListView";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetailPosCart, getUnits } from "../../../Redux/Actions";
import { capitalizeFirstLetter, isEmpty } from "../../../Utils";
import { STALE_TIME } from "../../../Constant/primitive";
import {
  posAction,
  posSelector,
  settingSelector,
} from "../../../Redux/Reducers/Slices";
import { useQuery } from "@tanstack/react-query";
import { POS_INPUT_REGEX } from "../../../Constant/regexConstant";
import {
  addStockWhenRemoveProduct,
  addStockWhenRemoveProductChange,
  updateStockWithInputChange,
  updateStockWithProductCart,
} from "../../../Redux/Reducers/ProductReducers/ProductReducers";

const ProductPOSListContainer = () => {
  const [change, setChange] = useState(false);
  const [layoutName, setLayoutName] = useState();
  const [isKeyboard, setIsKeyboard] = useState(false);
  const { productToCart, productSearchValue } = useSelector(posSelector);
  const [searchValue, setSearchValue] = useState("");
  const [productObj, setProductObj] = useState({});
  const dispatch = useDispatch();
  const {
    addProductToPOSCart,
    removeProductToPOSCart,
    deleteProductFromPOSCart,
    enterProductToPOSCart,
    removeProductFromPOSCart,
    mixMatchDiscount,
    bulkItemDiscount,
  } = posAction;
  const { unitsData, systemSettingDetails } = useSelector(settingSelector);
  const { mixMatch: mixMatchData } = useSelector((state) => state?.mixMatch);
  const { keyboardToggle, isOnScreenRightKeyboard } = useSelector(posSelector);
  const { isShowLeftKeyboard, isShowRightKeyboard, isShowRefKeyboard } =
    posAction;
  useEffect(() => {
    if (change) {
      dispatch(mixMatchDiscount({ mixMatch: mixMatchData, productToCart }));
      dispatch(bulkItemDiscount({ mixMatch: mixMatchData, productToCart }));
      setChange(false);
    }
  }, [change]);

  const handleGetProduct = async (searchValue) => {
    const payload = {
      searchedKeyWord: searchValue,
    };
    const response = await dispatch(getProductDetailPosCart(payload));
    if (response?.status === 200) {
      const responseData = response?.data?.data?.[0];
      if (responseData?.productType === 0 || responseData?.productType === 1) {
        dispatch(posAction?.isPosProductModal(true));
        if (
          productSearchValue === responseData?.productNumber ||
          productSearchValue === responseData?.barCodeId ||
          productSearchValue === responseData?.productName
        ) {
          dispatch(posAction?.posUnpackedProduct(responseData));
        }
        const data = [];
        for (let i = 0; i < responseData?.VegAndFruitsPackages.length; i++) {
          const payload = {
            productName: responseData?.VegAndFruitsPackages[i]?.packetName,
            tax: responseData?.VegAndFruitsPackages[i]?.tax,
            remainingQuantity:
              responseData?.VegAndFruitsPackages[i]?.newStocks?.[0]
                ?.remainingQuantity,
            price: responseData?.VegAndFruitsPackages[i]?.newStocks?.[0]?.price,
            newStocks:
              responseData?.productType == 0
                ? responseData?.newStocks
                : responseData?.VegAndFruitsPackages[i]?.newStocks,
            unit: {
              baseUnit: responseData?.VegAndFruitsPackages[i]?.productUnit,
              operatorValue: 1,
            },
          };
          data.push({ ...responseData, ...payload });
        }
        dispatch(posAction?.posPackedProduct(data));
      } else {
        dispatch(addProductToPOSCart(responseData));
        dispatch(updateStockWithProductCart(responseData));
      }
    }
    setSearchValue("");
    setChange(true);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value?.trim());
    dispatch(posAction.productSearchValue(value?.trim()));
  };

  const handleSearchData = () => {
    !isEmpty(searchValue) && handleGetProduct(searchValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchData();
    }
  };

  const handleRemoveItem = (productObj) => {
    const currentUnit = unitsData.find(
      (unit) => unit?.shortName === productObj?.productUnit
    );
    dispatch(
      removeProductToPOSCart({
        ...productObj,
        operatorValue: currentUnit?.operatorValue,
        unit: currentUnit?.shortName,
      })
    );
    dispatch(
      updateStockWithProductCart({
        ...productObj,
        operatorValue: currentUnit?.operatorValue,
        unit: currentUnit?.shortName,
        isRemoveItem: true,
      })
    );
    setChange(true);
  };
  const handleAddItem = (productObj) => {
    const currentUnit = unitsData.find(
      (unit) => unit?.shortName === productObj?.productUnit
    );
    dispatch(
      addProductToPOSCart({
        ...productObj,
        operatorValue: currentUnit?.operatorValue,
        unit: currentUnit?.shortName,
      })
    );
    dispatch(
      updateStockWithProductCart({
        ...productObj,
        operatorValue: currentUnit?.operatorValue,
        unit: currentUnit?.shortName,
      })
    );
    setChange(true);
  };
  const handleDeleteItem = (productObj) => {
    dispatch(deleteProductFromPOSCart(productObj));
    dispatch(addStockWhenRemoveProduct(productObj));
    setChange(true);
  };
  const handleChange = (productObj, event) => {
    const currentUnit = unitsData.find(
      (unit) => unit?.shortName === productObj?.productUnit
    );
    const value = event?.target?.value;
    if (POS_INPUT_REGEX.test(value)) {
      let obj;
      if (isEmpty(value)) {
        obj = {
          ...productObj,
          value: 0,
          operatorValue: currentUnit?.operatorValue,
          unit: productObj?.VegAndFruitsPackages?.productUnit,
        };
      } else {
        obj = {
          ...productObj,
          value:
            value > productObj?.newStocks?.[0]?.remainingQuantity
              ? productObj?.quantity
              : value,
          operatorValue: currentUnit?.operatorValue,
          unit: currentUnit?.shortName,
        };
      }
      setProductObj(obj);
      dispatch(enterProductToPOSCart(obj));
      dispatch(updateStockWithInputChange(obj));
    }
    setChange(true);
  };

  const handleBlur = () => {
    if (Number(productObj?.value) === 0) {
      dispatch(removeProductFromPOSCart(productObj));
      dispatch(addStockWhenRemoveProductChange(productObj));
    }
  };

  const handleGetAllUnit = async () => {
    const params = { page: 1, limit: 10 };
    const response = await dispatch(getUnits(params));
    return response;
  };

  const { isLoading: isUnitLoading } = useQuery({
    queryKey: ["unit"],
    queryFn: () => handleGetAllUnit(1, 10),
    staleTime: STALE_TIME,
  });

  const getMixMatchDetails = (productId) => {
    // Check if the product is part of any mix-match offer
    for (const mixMatch of mixMatchData) {
      const productInMixMatch = mixMatch.mixMatchProducts.find(
        (p) => p.productId === productId
      );
      if (productInMixMatch) {
        return {
          offerName: mixMatch.offerName,
          offerType: mixMatch.offerType,
          price: mixMatch.price,
          qty: mixMatch.Qty,
          productNameArray: mixMatch?.mixMatchProducts.map((ele) =>
            capitalizeFirstLetter(ele?.ProductModel?.productName)
          ),
        };
      }
    }
    return null;
  };
  const handleKeyboardInput = (newInput) => {
    handleSearchChange({
      target: { value: newInput },
    });
  };
  const onKeyPress = (keyValue) => {
    if (keyValue === "{enter}") {
      if (!isEmpty(searchValue)) {
        handleGetProduct(searchValue);
        setIsKeyboard(false);
      }
    }
    if (keyValue === "{shift}" || keyValue === "{lock}") {
      setLayoutName((prevLayout) =>
        prevLayout === "default" ? "shift" : "default"
      );
    }
    if (keyValue === "{bksp}") {
      const updatedSearchValue = searchValue.slice(0, -1);
      setSearchValue(updatedSearchValue);
      dispatch(posAction.productSearchValue(updatedSearchValue));
    }
  };

  const handleFocusSearchInput = () => {
    setIsKeyboard(true);
    dispatch(isShowLeftKeyboard(false));
    dispatch(isShowRightKeyboard(true));
    dispatch(isShowRefKeyboard(false));
  };
  return (
    <ProductPOSListView
      {...{
        productToCart,
        handleRemoveItem,
        handleAddItem,
        handleDeleteItem,
        searchValue,
        handleSearchChange,
        handleKeyDown,
        handleChange,
        isUnitLoading,
        systemSettingDetails,
        handleBlur,
        getMixMatchDetails,
        handleFocusSearchInput,
        handleKeyboardInput,
        onKeyPress,
        layoutName,
        keyboardToggle,
        isKeyboard,
        setIsKeyboard,
        isOnScreenRightKeyboard,
      }}
    />
  );
};

export default ProductPOSListContainer;
